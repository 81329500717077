import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'

interface ITwilioProxyService {
  $axios: NuxtAxiosInstance
  proxyUrl(url: string): Promise<File>
}

export default class TwilioProxyService implements ITwilioProxyService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  proxyUrl (url: string): Promise<File> {
    return this.$axios.$get('/api/user/twilio-file-proxy', { params: { url }, responseType: 'blob' })
  }
}
